import React from 'react'
import Check from './Check'
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'

const PersonalDataList = ({data}) => (
  <div className="sm:grid sm:grid-cols-2 xxl:grid-cols-4 gap-5">
    <SkeletonTheme baseColor="#D7E0EA" highlightColor="#F5F7FA" borderRadius={3} height="1.5em">
    {data.map(group => {
      return <ul className="list-none p-0">
        {group.map(item => {
          return <li key={item.label} className="flex items-center justify-start pb-3">
            {item.label && <span className="relative w-10 md:w-16 flex items-center justify-start">
               <Check size='small' variant={item.status ? 'on' : 'disabled'}/>&nbsp;
            </span>
            }
            <span className="flex-auto leading-7">
              {item.label || <Skeleton />}
            </span>
          </li>
        })}
      </ul>
    })}
    </SkeletonTheme>
  </div>
)

export default PersonalDataList
