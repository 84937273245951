import React, { FunctionComponent, useState } from "react"
import Modal from 'react-modal'
import BreezyHelping from "./BreezyHelping"
import Info from "./icons/Info"
import X from "./icons/X"

const customStyles = {
  overlay: {
    zIndex: 50,
    display: 'flex',
    alignItems: 'center'
  },
  content: {
    margin: '0 auto',
    // overflow: 'hidden',
    maxWidth: '80ch',
    borderRadius: 20,
    border: 0,
    padding: 0,
    boxShadow: '0px 4px 50px 30px rgba(0, 0, 0, 0.1)',
    outline: 'none'
    // inset: 0
  },
}

const ModalDataProfile: FunctionComponent<any> = () => {
  const [open, setOpen] = useState(true)

  function closeModal(e) {
    e.preventDefault()
    setOpen(false);
  }

  return (
    <Modal isOpen={open} style={customStyles} className="bg-white relative h-full xl:h-auto overflow-y-auto" bodyOpenClassName="overflow-hidden">
      <div className="flex flex-col h-full">
        <div className="flex justify-between items-center sticky top-0 bg-white py-3 px-4" style={{boxShadow: '0px 2px 5px 5px rgba(255, 255, 255, 0.6)'}}>
          <Info/>
          <a href="#0" className="opacity-50 ease-in duration-200 hover:opacity-100" onClick={(e)=>closeModal(e)}><X /></a>
        </div>
        <div className="px-5 md:px-12 pt-3 pb-12 flex flex-col flex-auto">
          <BreezyHelping className="mx-auto max-w-xl"/>
          <h2 className="text-center max-w-4xl mx-auto pt-6">One of our 400k+ brands wants to partner with you!</h2>
          <div className="md:grid grid-cols-2 text-3 gap-16 max-w-4xl mx-auto flex-auto">
            <div className="pt-3">
              <p>Breezy is the market's most powerful partner intelligence and discovery platform, designed to help brands
                find highly targeted partnership prospects.</p>
            </div>
            <div className="pt-3">
              <p>
                And guess what? One of our brands wants to partner with you!
              </p>
              <p className="pt-3">
                They've asked us to dig out your contact details so that they can get in touch to talk about a mutually beneficial partnership opportunity.
              </p>

            </div>
          </div>
          <div className="flex flex-col items-center pt-6 flex-end">
            <a href="#0" onClick={(e)=>closeModal(e)} className="rounded-full text-center leading-5 bg-blue hover:bg-blue-400 ease-in duration-200 px-16 py-3 text-white text-3 cursor-pointer">Okay, show me to my data profile</a>
            <small className="block text-center text-3 text-gray-700 pt-2">You can opt-out here if you wish.</small>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalDataProfile