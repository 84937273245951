import React, { FunctionComponent, useEffect, useState } from "react"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { useQueryParam } from "gatsby-query-params"
import "react-loading-skeleton/dist/skeleton.css"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import Container from "../../components/Container"
import PersonalDataList from "../../components/PersonalDataList"
import BreezyLaptopHand from "../../components/BreezyLaptopHand"
import { graphql } from "gatsby"
import Alert from "../../components/icons/Alert"
import ModalDataProfile from "../../components/ModalDataProfile"
import { gql } from "@apollo/client"
import GqlRequest from "../../utils/gql-client"

const DATA_POINTS = [
  {
    firstName: "First Name",
    lastName: "Last Name",
  },
  {
    jobTitle: "Job Title",
    company: "Company",
    role: "Role",
  },
  {
    linkedin: "LinkedIn profile",
    twitter: "Twitter profile",
    instagram: "Instagram profile",
  },
  {
    email: "Email",
    companyEmail: "Company Email",
    phone: "Phone",
  },
]

function apiConvert(contact) {
  let email = null
  const { channels, contactData } = contact

  const points = contactData.map(({ kind, data }) => {
    if (kind === "email") {
      email = data
    }
    return kind
  })

  const dataPoints = schema =>
    schema.map(group => {
      return Object.keys(group).map(key => {
        return {
          label: group[key],
          status: points.includes(key),
        }
      })
    })

  const website = channels && channels[0] ? channels[0].domain : undefined

  return {
    email,
    website,
    dataPoints: dataPoints(DATA_POINTS),
  }
}

const GET_CONTACT = gql`
  query GetContact($token: String!) {
    contactByToken(token: $token) {
      id
      status
      channels {
        channel {
          domain
        }
      }
      contactData {
        id
        kind
        data
        status
      }
    }
  }
`

const OPT_OUT = gql`
  mutation OutOut($token: String!) {
    optOutContactByToken(token: $token) {
      id
    }
  }
`

interface QueryResult {
  mdx: {
    frontmatter: {
      title: string
      seo: {
        title: string
        description: string
      }
    }
    body: string
  }
}

interface Props {
  data: QueryResult
}

interface ContactResponse {
  success: boolean
  data?: {
    contactByToken: {
      id: string
      status: string
      channels: Array<{ domain: string }>
      contactData: Array<{ id: string; kind: string; status: string }>
    }
  }
}

const DataProfile: FunctionComponent<Props> = ({ data }) => {
  const page = data.mdx
  const token = useQueryParam("token", undefined)
  const [optout, setOptout] = useState(false)
  const [loading, setLoading] = useState(true)
  const [thinking, setThinking] = useState(false)
  const [contactData, setContactData] = useState({})

  useEffect(() => {
    if (token) {
      GqlRequest.query({ query: GET_CONTACT, variables: { token } }).then(
        (response: ContactResponse) => {
          if (response.success) {
            const { success, data: rest } = response
            if (success) {
              const { status, ...contact } = rest["contactByToken"]
              if (status === "optedout") {
                setOptout(true)
              }
              setContactData(apiConvert(contact))
            }
          }
        }
      )
    } else {
      setLoading(false)
    }
  }, [token])

  const onOptout = async e => {
    e.preventDefault()
    setThinking(true)

    const { success } = await GqlRequest.mutation({
      mutation: OPT_OUT,
      variables: { token },
    })

    setOptout(success)
    setThinking(false)
  }

  return (
    <>
      <ModalDataProfile />
      <Layout>
        <Seo
          description={page.frontmatter.seo.description}
          pathname="/data-profile"
          title={page.frontmatter.seo.title}
        />

        <Container style={{ maxWidth: "100ch" }}>
          <header className="text-center max-w-5xl mx-auto pt-16">
            <h1>{page.frontmatter.title}</h1>
            <p>{page.frontmatter.seo.description}</p>
          </header>

          <section className="pt-12 md:pt-24 rounded-lg md:rounded-xl bg-gray-200 p-6 md:p-20 mt-6 md:mt-16">
            <h2>Manage your data</h2>
            <SkeletonTheme
              baseColor="#D7E0EA"
              highlightColor="#F5F7FA"
              borderRadius={3}
              height="1.5em"
            >
              <p>
                {contactData.email ? (
                  <>
                    We've linked the email address{" "}
                    <strong>{contactData.email}</strong> to your website
                    {contactData.website && (
                      <strong> {contactData.website}</strong>
                    )}
                    .
                  </>
                ) : (
                  <Skeleton />
                )}
              </p>
            </SkeletonTheme>
            <section className="mt-4 md:mt-8">
              <h3 className="pb-3 font-sans font-semibold">
                Data we've collected:
              </h3>
              <PersonalDataList
                data={
                  contactData.dataPoints || [
                    [{}, {}, {}],
                    [{}, {}, {}],
                    [{}, {}, {}],
                    [{}, {}, {}],
                  ]
                }
              />
            </section>

            <section
              className={`${
                optout ? "bg-red-200" : "bg-white"
              } transition-colors ease-in duration-200 rounded-lg md:rounded-xl p-6 md:p-16 mt-8 md:mt-16 mx-auto`}
            >
              <div className="max-w-4xl mx-auto">
                <h3 className="flex items-center font-sans font-semibold">
                  <Alert style={{ width: "1.2em", height: "1.2em" }} />
                  &nbsp;&nbsp;&nbsp;
                  {optout ? "You have opted out" : `Don't miss out!`}
                </h3>
                <p className="pt-3">
                  By opting out, you ensure we'll no longer pass your contact
                  details onto brands looking to partner with you. But that
                  means losing out on a ton of exciting partnership
                  opportunities.
                </p>
                <h4 className="pt-3 md:pt-6 font-bold">What you will lose:</h4>
                <ul className="pt-1 md:pt-3 list-checked list-checked-off">
                  <li>
                    Hear from brands actively interested in collaborating with
                    you.
                  </li>
                  <li>
                    Negotiate mutually beneficial partnership opportunities.
                  </li>
                  <li>Get yourself in front of the 400k+ brands on Breezy.</li>
                </ul>
                <section className="flex justify-center md:pt-3 pb-6">
                  <a
                    href="#"
                    className={`${
                      thinking || loading || optout
                        ? "bg-gray-400 cursor-not-allowed"
                        : "bg-red hover:bg-red-400 cursor-pointer"
                    } rounded-full ease-in duration-200 px-16 py-3 text-white `}
                    onClick={onOptout}
                  >
                    Opt-out
                  </a>
                  &nbsp;&nbsp;
                </section>
                <small className="block text-center text-gray-700">
                  By taking no action your data will remain on the platform.
                </small>
              </div>
            </section>
          </section>

          <article className="py-12 md:py-24 text-center">
            <h2 className="pb-6">What is Breezy?</h2>
            <BreezyLaptopHand />
            <div className="max-w-4xl text-left mx-auto">
              <p className="pt-12">
                Breezy helps brands and partners to find data-driven partnership
                opportunities (way!) more quickly and easily.
              </p>
              <ul className="list-checked text-left pt-3">
                <li>
                  Uncover thousands of hyper-relevant prospects with our
                  advanced partner search engine.
                </li>
                <li>
                  Gain valuable insights into niche partner ecosystems with our
                  unique partnership database.
                </li>
                <li>
                  Uncover the untapped opportunity with powerful gap analysis.
                </li>
              </ul>
              <p>
                In short, we're all about helping brands and partners to find
                one another so that you can spend less time searching for
                suitable leads and more time making your partnerships a success!
              </p>
            </div>
          </article>
        </Container>
      </Layout>
    </>
  )
}

export const query = graphql`
  query dataProfile {
    mdx(fileAbsolutePath: { regex: "//static/data-profile/" }) {
      frontmatter {
        title
        seo {
          description
          title
        }
      }
      body
    }
  }
`

export default DataProfile
