import React from 'react'

const BreezyLaptopHand = () =>

<svg viewBox="0 0 1278 514" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="a" style={{maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="1278" height="514">
    <path d="M1278 0v275.3c-.4 124.6-102.1 238-227.2 238H0V0h1278Z" fill="#E5FCFA"/>
  </mask>
  <g mask="url(#a)">
    <path d="M1278 0v275.3c-.4 124.6-102.1 238-227.2 238H0V293A293 293 0 0 1 293 0h985Z" fill="#E5FCFA"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M909.1 329.8 411.7 477.7l80.6-457 416.8 309Z" fill="#FDFFFF"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M481.1 415.2c40.5-21.5 84.8-36.1 128.5-50 2.7-1.3-1.8-4-3.4-2.9-33.4 9-65.4 22-98.6 31-11.7 3.2-23.2 7.5-34.6 11.4-2 1.3 6.5 9.8 8.1 10.5Zm111.3-26.6c-5.6-5.5 21.5-13 25.6-15 4.2-.7-5.4-8.3-6.3-4.7-8.7 6.5-21 8-31 12.4-25.8 8.5-50.8 19.5-76.6 28.2-5.2 1.7-10.3 3.6-15.3 5.8-6.5 2.9-5.6 2-1 6.6 1 1 2 1.2 3.5.5 5-1.3 39.8-20.6 39.6-12.7-7.4 8-24.8 10.6-35 16-5.5 1.4-2 4.8.8 7.2 4 2.6 7 7.3 11.8 3.3 13-6 27-9 40.3-14.4 27-9.6 54.5-18.5 80.9-30.2 14-4.8-.6-6.1-6.9-15.3-8.9 4.3-20.1 9-30.4 12.3Zm-87.2 59c-4.4.6-16.2-7-18.7-10.5-1.8-2.6-3.4-5.4-6.6-6.8-5.3-6.3-11-12-15.6-19.1-1.3-2.7-5-5-4.4-8.1.7-2.2.6-5.2 3.4-5.9 46.4-14.7 93-28.6 139.3-44 14.5-3.6 23.6 17.7 34.8 24.3 11.9 9.1 18.7 14.5-.2 20.4a1215.4 1215.4 0 0 1-132 49.6Z" fill="#8FBAFD"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M562.9 331c-2.8-5.4-13.5-51.2-19-47-34 8-67.3 17.8-99.8 30.7-9.2.7 5.5 13.2 7.6 16.2 4 3.9 5.3 9.2 7.3 14.1 2.2 6 6.4 10.8 8.6 16.7.7 2 1.4 2.2 3.3 1.4 13.5-6 28.2-8.1 42.2-12.3 16.5-6.5 33.9-12.9 49.8-19.8Zm-133.3-20.4c-.6-5.8 1.7-3.1 5.2-4.6 15.4-2.2 29.2-10 44-14.6 20.4-7.2 41.7-11.1 62.6-16.5 16.8-4.6 16.7 12.2 22.3 23 4.2 9.1 6.4 19 9.6 28.4 1.4 3.7 2.1 8.2-2.3 10-25.3 9.6-49.5 22.6-76.1 28.3-9.2.6-24.5 12.3-31.8 7.2a94.4 94.4 0 0 1-15.1-25.7 41 41 0 0 0-7.7-12.6c-4.7-6.5-10.4-15.1-10.7-23Z" fill="#B1CFFD"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M484.8 219.2c-7.8 1.4-16 4.4-15.2 13.4 1.1 2.8 13.3-11.5 15.2-13.4Zm4.8 1.4c-6.7 4.2-11.4 11.5-17.3 16.9-.6.5-1.2 1.2-.9 2 4 6 8.3-3.8 11.8-6l9.2-8.2c2.4-1.7-1.9-3.4-2.8-4.7Zm6.7 11.5c-.2-1.4 0-6.4-2.5-4-4.8 5.3-10.7 9.9-14.8 15.8 8 5.7 17-3.6 17.3-11.8Zm6.5 2c-1.1 7.4-11 19.3-19.8 17.6-3.9-1-8.1-1.9-11.8-3-1.7-3.3-5.9-5-7.1-8.4-9.4-31.5 37.6-41.3 38.7-6.1Z" fill="#3A86FB"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M390.3 343c-5.5.8-17.4-4.3-9.4-9.8 7.5-.8 15.2-2.7 22.6-.8 1.8.6 1.6 2.6.8 4-.3 7-9.1 5.3-14 6.5ZM411.9 373.5c4.3-.8 10.6 3.5 7.3 6.7-6.4 1.7-21.9 19.4-22.4 4 3.5-5 8.8-10.2 15-10.7ZM420.8 428.5c1.3-5.7 5-11 9-15 4.4-5.2 11.3-1.5 7 4.5-3.9 4.4-11.7 24.8-16 10.5ZM387 287.7c-4.2 1-14.8-2.8-11.3-7.8 9-6.8 36.1 7.8 11.2 7.8ZM408.5 252.8c3.2-12.2 11.8 3.4 15 8 2.9 2.7 0 5-3 4.9-5.7-1.9-11.9-6.4-12-12.9ZM470.7 458.2c-.1-3 .3-9.8 4.5-9 1.6.4 3.3.6 3.4 2.8-.2 4.3-1 8.6-1.3 13-3.7 7.9-7.4-2.7-6.6-6.8Z" fill="#B1CFFD"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M659 392c-17.2-11.2-34.1-25.5-51.3-36-42.8 14-85.4 29.2-129.2 40-4 1.7-16.2 2.5-16.7 6.4 14.4 13.7 28.1 28.2 42.1 42.3 2 2.3 4 3.7 6.8 2 15.5-5.8 31-11.4 46.6-17C591 416.6 626.5 406.6 659 392Zm-53.5-42.2c-1.4-10-7.6-19-11.9-28-10.1-19.8-16.9-41.2-26.6-61.3-6-18-4-11.5-21.4-8.8-22.4 6-44.5 12.7-66.3 20.4-13.2 4.8-26.6 8.9-40 13.2-3.3 1.5-7.8 2-10.5 4.4-.5 1.3-1.6 1.8-2.6 2.4l-14.6 9.2c-1.7 1.4 1 3.7 1.3 5.3 13.3 29.2 22.3 60.2 39.7 87.4 1 1.4 2.2 4 4.1 3 3.6-2 7.4-4 11.4-5 24-5.4 47.4-13 70.9-20a750.4 750.4 0 0 0 55.8-17.5c3-1.8 9.7-1.2 10.7-4.7Zm-193-54c-12-1.2 16.8-11.8 19-13.7 33.3-12.7 67.2-25.8 102.2-34.2 36.7-7.5 30.5-13.1 43.2 18.3 7.9 16.7 12.6 34.7 21.2 51.1 4.6 9.9 10.7 19.3 13.3 30 .3 1.9 2.7 1 3.7 2.4 6.7 5.4 14.2 9.2 21 14.4 4.7 5.1 50.1 29 29.3 31.6-54 18-106.8 42-161.4 57.2-10.8-2.6-15-15.3-23.5-21.5-9-8.2-16.1-18.4-26.1-25.3-14.7-15.3-22.3-38.2-32.7-56.6-6.2-5.4-7.2-13.4-10.6-20.3-6.4-9.5-10.2-20.4-7.3-31.8.3-1 2.4-1 3-.6 2.4 1.6 3.4-.2 5.7-1ZM432.5 458.2c.4-5 8.3-26.2 14.7-21.7 3 5-6.2 17.9-7.8 23.8-2.9 7-5.8 2.7-7-2Z" fill="#000"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M642.5 325.9c-4 0-28.6-3.2-19-9.5a70 70 0 0 1 26.8 3.5c5 4.2-4.5 6.3-7.8 6ZM619.8 297.4c-17-.5 26.3-16.7 27.2-12.6 7 8-22.5 10.7-27.2 12.6ZM381.3 373c-12.9-1.8 19.8-19.2 22.6-14.3 1.2 5.2-18 12.8-22.6 14.3ZM399.1 421c-1-4.4 17.9-24.8 19.7-15-2.2 2.7-18 23.6-19.7 15ZM660.5 354.9c-3.8-1.7-14.6-1.2-16-5.4 1-6.2 23.4 1.9 16 5.4ZM569.5 336c-.3-4.2 3.3.7 5-1-3-12.5-11.6-24.9-17-37-3.3-6.8-6.7-13.5-8.8-20.7-.4-3.2-3.4-1.8-5.6-1.6-29.2 7-57.3 17.6-86.6 24.6-7.7 2.3-15.2 5.1-22.9 7.5-3.1 1.8 2.6 4.8 3 7 7 14.9 13.3 30 23 43.2 3.4 3.5 8.3 17.9 13.8 14.1 31.5-12.8 66.2-20.9 96-36.1Zm-99.7 42.7c-9.7-.7-13.7-13.4-18.7-20.3-11.3-13.8-14.9-31.7-23.3-47-5.5-7 31.8-16.7 37-19.3 28-7.2 55-18.7 83.4-24 9.4-1.6 8.8 15.3 13.7 21a318 318 0 0 1 18.5 41.8c.6 2.1 3 4.5 1.2 6.7-4.1 6-12.2 3.5-17.7 7.1-30.7 12.9-62.7 24-94.1 34ZM594.9 391.6c-14.9-7.4-18-7.4-32.8-.5-6.1 2.7-12.3 5.4-17.9 9.1-4.4 3-4.4 2-1.6 6.5 1.6 1.7 2.3 6.7 5.2 5.5 6.4-2.7 12.8-5.4 19-8.3 9-5 19.7-7 28.1-12.3ZM531 402.4c5.4-9.5 20-13 29.7-17.3 4.3-2.4 13.8-1.2 16.2-4.4.3-.8 1.5-1 2.5-.9 4.5.2 31 7.9 23 14.3C586 403 568 409 551.4 417.6c-6.6 4.8-9.6 2.8-13.4-3.7-2.4-3.8-6.4-7-6.9-11.5Z" fill="#000"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M523.6 305.8c-2.4.4-1.3 2.1-.2 3.5 5.3 5-.3 16 2.8 15.5 6.4-1.7 10-7.4 13.4-12.5 2.7-3.6-13.4-5.8-16-6.5Zm-38 28.3v.1c26.7-1.2 12.4 1.2 10.4-13.5-.6-2 1.9-10.5-1-10-8.9 4-12.9 13.7-17.5 21.7-.7 3.3 6.1 1 8.1 1.7Zm1.9 6.6c-25.8.2-16.4-5-8.4-23.5 7.7-13.6 24.1-18.8 39-18.1 9-.7 17.2 2 25.6 4.7 8.5 3.3 1 12.4-1.7 17.5-5.5 9.6-16.8 11.5-26.3 15.5-9 3.8-18.6 4.6-28.2 4.2v-.3Z" fill="#000"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M524.7 361.4c42.3.8 85 9.2 127 2.8 13.3 2.6 31.8 37.7 43.4 48.2 3.8 4.4 17.1 16.4 5.8 18.9-3.5-1-6.9-.5-10.4-.2-14 .6-28 1.4-42 1-37 .4-74-1-110.2-9.8-15.5-.8-40.7-5.1-14.3-19.3 2.4-1 2.2-2.4-.1-2-12-.3-21-9.1-7.2-16.8 25.8-7.9 7.8-1-6.2-13-6.6-6.1 10.7-10.7 14.2-9.8ZM468 200.9c-4.6 12.2 2.9 30.4-9.6 39.2-21.5-1.2-16-30.7-16.4-45.7a64.3 64.3 0 0 1 26.6-61.8c12.7-11.4 19.6.4 28.5 9.6 10 10.2 20.3 20.2 30.3 30.4 4.6 3.5-.7 6.5-.5 10.8.2 7.5 0 15-.6 22.4-1.5 9.2 2.6 18.3.4 27.4-1.7 18.3-14.7 17-24.6 4.9-5.6-7.8-4.5-17.7-4.7-26.7 1-4.5-2.5-5-1.5 0-.4 12.5 3 29.3-9.3 37.4-12.3 2.7-18.6-17.6-18.5-27.4 0-7 1.1-13.6 0-20.5Z" fill="#B1CFFD"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M612 140.7c-9.2 4.6-18.4 6.2-26.6-.7-2-1.5-2.3-3-1-5.3 5-7.2 13-11.9 20.2-16.6 2.1-1.9 19-8 9-7.4-34.9-5-11.3-22 9.3-23.8 25-2.6 51 .9 75.3 7 8.1.8 14.8 8 6.7 14.2-10.8 8-22.3 15.1-33 23-17.7 12.7-34.6 26.4-52.5 38.7-8.2 4.8-26.2 31-32.8 13.5a46.7 46.7 0 0 1 27.6-42.9c-.8-.2-1.6-.3-2.3.3Z" fill="#8FBAFD"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M769 248.2c5.1-.5 12-4.4 15.9.7 2 3.1 3.8 6.3 1.5 10-1.9 4.5-6.2 7.5-8 11.8 3.2.4 6.8-1.5 8.8 2.1 5.9 8.7-5.8 15.8-9.9 22.6-2 2 1.9 4.6 2.3 7 1.1 8.3-6.6 18.2-14.9 20.1a87.8 87.8 0 0 1-39-.2c-7.7-1.6-10.8-9-8.2-16 4-14 7.7-28 11.9-42a76.2 76.2 0 0 1 31.1-34.4c3.4-1.7 10.2-4 12.3.4 2 2.9 3.1 5.7.8 8.8-1.9 3-4.3 6-5.8 9.3l1.3-.2Z" fill="#3985FB"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M543.3 215.6c.3-6 2-27.4 10.5-25.5 3.2.5 5.2-4 7.6-.2 6.4 6.6 29.8 34 8 33.3-3.4 0-8-8.6-9.6-4.7-5.7 13.5-17.4 12-16.5-3Z" fill="#0162F7"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M534.9 175.3c-3 0-3.3-.1-4-3.3-1.1-5.8-3.7-11-6.4-16.3-6.2-13.6-16.5-26.8-17.9-42 1.4-4.7 7-2.3 8 1.5 2.8 9 34.7 58.4 20.3 60.1ZM556.9 162.9c0 1.8-.6 4-2.9 3.8-4.2.6-4.3.6-5.5-3.5-2.8-17-10-32.7-14-49.2 7-22.2 21.9 43.2 22.4 48.9Z" fill="#B1CFFD"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M609.9 110c-20.6-7-6.5-25.7 8.4-26.6 26.8-3 55.9-2 80.7 9.3 10.9 6 27.2 10.1 32 22.4-6.4-2.5-12.2-7.6-19-9.8-15.9-7.5-31.9-13.9-49.6-15-16.7-1.6-37.5-4.5-52.6 4-13 11.7 14.2 10.3 20.7 10 5.6-.3 7.3-.3 7.9 5.8a7.7 7.7 0 0 1-5.9 1.8 53 53 0 0 0-24 6.8c-11 6.3-36.2 28.7-5.8 18.3 3.7-.7 18.2-7.4 20-4-.5 1.8 2.2 4.1 0 5.3a52.8 52.8 0 0 0-21 16.8c-8.6 8.5-12 36.4 6.8 26.6 10.4-9 18.9-19.9 28.8-29.4 1.5-1 2.2.9 2.3 2.1 1 3.3 6.1 2 6.2 6.4a403 403 0 0 1 7 41c6 28.1-1.5 28.4 22.7 13.6 2.3-1.8 7-.4 6 3-.1 1 0 1.9.5 2.7 3.7 5.5 8.3 10.5 12.3 15.8 4.1 4.4 21.2-3.9 21.3-10 1-4.3.6-8.5 0-12.8 0-2 3-1.4 4.3-2 2.6-.2 2.2 3.4 2.6 5 2.3 34.2-47.4 16.8-5.7 35.5 12.6 5.5 12.5-1 18.5-10 4.4-8 18.9-22.4 23.6-7.2-1.4.2-1.8-.8-2.3-1.4-5-4.8-10 2.6-13 6.1-2.8 4.4-5.3 9-8 13.4-1.5 2 .4 2.6.8 4.2-9 3.2-10.3 21.7-12.1 30.3-4.8 32-15 25.6 19.8 31.4 2.4 1 7.6-.6 8 2.6-.4 1.7.4 5-2.4 4.5-37.3-2.9-28.8-15.7-38.5 7.9a58.5 58.5 0 0 1-46.3 32c-22.7 3-45.5 2.9-68.3 3.4-28.6 0-58.7-11.5-86.4-1.3-2.1 6.4 49.2 7.8 41.5 16-11.1 3.3-23.5.4-34.2 6.8-5.6 2.8-5 3.8 1.3 4 8.1.6 16.3 1 24.2 2.8 12.3 6.3-6.2 7.6-11 8-3.4.1-16.5 4.4-10 7.8 5.2 2.6 11 .4 16 2.9 17.8 2.2 35.6 5.8 53.6 6.8 24 1.3 47.7.5 71.7-.3 22.8-1.3 60 .7 77.2-15.7 4.4-5.5 8-11.4 9.1-18.5.8-3 1.2-6.5 4-8.4 1-.8 2.5.4 3.3-1l.7.4c8.4 7.2-1.6 18-5.4 25.2-20 29.7-87.3 25-120.1 26.2-20.5-.8-41.2-.3-61.7-2.4-14.3 0-28-4.9-42.3-6.5-7.6 0-19.5-6.3-12.2-14.8 1.3-2.8 7.4-3 7.5-5.7-31.3-2-15.5-13.7 3.2-20.5-6.5-3.3-12.9-3.2-19.2-6.5-14.5-11 12.8-16.5 20.8-16.6 27.4-1.5 54.4 5.6 81.9 4.4 37.4.3 84.7 4.8 99.4-39 7-20 6.5-41.9 14.6-61.5.5-.9.6-2.3-.8-2.3-8.1 0-15.4-3.3-22.6-6.4-11.1-5.9-18.2-17.8-25.3-27.9-2.3-6-16.9 10.8-20.2 6.3-11.5-10.2-13.1-50-16.6-66.3 0-.9-.4-2.3-1.5-1.8-12.6 8-21.3 28.6-38.7 24.9-6.7-1.4-13-14.4-7.8-19.5-3.7-7.4 7.4-18.6 11.4-24.7-40.8 5.5-9.7-26 7.9-34.7Z" fill="#000"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M513.2 250.8c-6.7-2.2-10-9-13-15-2.8 7.2-6.3 15.6-15.3 15.2-10.8.3-13.9-13-16.7-21.2-3 4.4-2 10.6-6.2 13.7-8.9 3.7-14.6-6.5-18.4-12.9a99 99 0 0 1-4.5-40.2c-1-34 11.5-56.2 43-70 28.2-12.7 58.5-20.7 89.5-16.4 6.6 1 15 .3 19.3 6.4-39.2-2.7-66.3-3-103.2 13.2-37.1 16.6-42.1 37-42.8 74.9-1.4 7.9 1.7 15.3 2.4 23 .6 7.1 4.2 14.2 10 18.3 5.4 1.8 6.5-26.4 7.7-31 .7-3.6-.5-7.8 1.6-11 2-2.1 5.3-2.5 6.1.8 1.7 9.2 1.7 18.7 2.8 28 .6 6.4 2.7 13 7.7 17.5 11.7 6.7 12.9-25 13-31.8-1.1-15.2 8.6-14 8.2.3 1 9.5-.5 20.1 5.3 28.3 12.8 15.4 10-5.4 10-14 0-7.1.2-14.2.1-21.3-.3-8.8-2.9-20 5.4-26.1a89.9 89.9 0 0 1 50.4-17c9.3 3-3.8 7.3-7.7 6.6a42.1 42.1 0 0 0-23 6.8c-1.8 1.8-4.3.3-6.3 1.9-4.6 3.5-12.4 5.7-12.4 12.5.8 14.3 1 28.5.8 42.9.5 8.5-2.2 23-13.8 17.5.4.3.8.4 1.2 0-.4-.3-.7-.2-1.2.1ZM693.5 183c-14.8 3.2-28-32.4-8.5-7.3 4.2 4.5 15.3 4 15.4-3.4 0-7.3-6.2-8.5-5.9-13.3 6-7.7 21.5 23.7-1 24Z" fill="#000"/>
  </g>
</svg>

export default BreezyLaptopHand