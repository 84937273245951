import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'

const GQL_LINK = 'https://api.breezy.io/graphql'

class GqlClient {
  constructor () {}

  async query ({ query, variables = {} }) {
    return new Promise(resolve => {
      this.Request.query({
        query,
        ...(Boolean(variables) && { variables })
      })
        .then(({ data }) => {
          resolve({ success: true, data })
        })
        .catch(() => resolve({ success: false }))
    })
  }

  async mutation ({ mutation, variables = {}, unauthorized }) {
    return new Promise(resolve => {
      this.Request.mutate({
        mutation,
        ...(Boolean(variables) && { variables })
      })
        .then(({ data }) => {
          resolve({ success: true, data })
        })
        .catch(() => resolve({ success: false }))
    })
  }

  get Request () {
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers
        }
      }
    })

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    })

    const httpLink = new HttpLink({
      uri: GQL_LINK
    })

    return new ApolloClient({
      link: ApolloLink.from([ errorLink, authLink, httpLink ]),
      cache: new InMemoryCache()
    })
  }
}

export default new GqlClient()
